/****** QUIZZES PAGE CSS ******/

.hero-section-home {
  display: flex;
  padding: 100px;
  box-shadow: inset 0 0 0 100vw rgba(2,17,1,0.8);
  background-repeat: no-repeat;
  background-size: cover;
  color: #FFFFFF;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  text-align: center;
}

.hero-section-home p{
  text-shadow: 0px 1px 13px rgba(0, 0, 0, 1);
  margin: 15px 0 25px 0;
  font-size: 14px;
  font-weight: 500;
}

.hero-section-home h1{
  text-shadow: 0px 1px 13px rgba(0, 0, 0, 1);
  font-size: 64px;
  font-weight: 600;
}

.hero-section-home p{
  font-family: 'Inter', sans-serif;
  font-size: 18px;
}

p.hero-sub-title{
  font-size: 28px;
  font-family: 'Caveat', cursive;
  font-weight: 600;
  margin: 0 0 48px 0;
  color: #7CF065;
}

.try-link{
  margin-top: 48px;
  font-size: 18px;
  font-family: 'Inter', sans-serif;
}

.try-link > a{
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  color: #7CF065;
}

.why-sub-asset{
  position: relative;
  margin: 48px auto 0 auto;
  height: 441px;
  padding: 0;
}

.why-sub-asset video{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}


/****** QUIZZES ******/

.quiz-block{
  width: 320px;
  background-color: #FFFFFF;
  margin: 48px auto 0 auto;
  text-align: left;
  padding-bottom: 18px;
  box-sizing: border-box;
  box-shadow: 0 2px 16px 0 #DFE9DD;
}

.quiz-block img{
  width: 320px;
  height: 320px;
  background-color: #FFFFFF;
}

.quiz-meta{
  padding:18px;
}

.quiz-source{
  color: #B7C0B5;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  letter-spacing: -0.2px;
  margin-bottom: 12px;
  font-weight: 700;
}

.quiz-block p{
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #031101;
  margin-bottom: 18px;
  letter-spacing: -0.2px;
  line-height: 24px;
}

.quiz-block a{
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.start-quiz{
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #1CA800;
  letter-spacing: -0.2px;
  margin: 0;
  text-decoration: underline;
}

.start-quiz:hover{
  color: #031101;
  transition: 0.4s;
}


/*------ MEDIA QUERIES ------*/

@media screen and (max-width: 1150px) {
  .quiz-container{
    grid-template-columns: auto auto;
  }
}

@media screen and (max-width: 770px) {

  .hero-section-home h1{
    font-size: 40px;
  }

  .quiz-container{
    grid-template-columns: auto;
  }

  .why-sub-asset{
    height: 260px;
  }
}

@media screen and (max-width: 400px) {
  .hero-section-home h1{
    font-size: 34px;
  }
}


















/*end*/
