/****** ABOUT US PAGE CSS ******/

.hero-section-about{
    background-color: none;
    padding: 96px 0;
    text-align: center;
    height: auto;
}

.hero-section-about h1{
  font-weight: 600;
}

.content-block-about{
  text-align: left;
  color: #031101;
}

.content-block-about h4{
    font-size: 20px;
    margin-bottom: 16px;
    font-family: 'Inter', sans-serif;
}

.content-block-about strong{
  font-family: 'Inter', sans-serif;
}

.contact-block-container{
  display: flex;
  justify-content: space-between;
}

.contact-block{
  background-color: #FCFFFB;
  border: 1px solid #DFE9DD;
  width: 350px;
  padding: 24px;
  box-sizing: border-box;
}

.contact-block h5{
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #7A7E79;
}

.contact-block p{
  color: #7A7E79;
  font-size: 16px;
  margin: 12px 0 24px 0;
  letter-spacing: -0.2px;
  line-height: 24px;
}

.contact-block a{
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: #1CA800;
  font-weight: 700;
}

.contact-us{
  background-color: #F3FBF2;
  padding: 30px;
  margin-bottom: 90px;
  border-radius: 13px;
  box-sizing: border-box;
}

.contact-us a{
  color: #1CA800;
}

.content-block-two-col{
  display: flex;
  justify-content: space-between;
}

.content-block-about img{
  width: 350px;
  margin-left: 64px;
}

.content-block-about a{
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  color: #1CA800;
}

.content-block-about .two-button-container{
  margin: 0;
}


/*------ MEDIA QUERIES ------*/
@media screen and (max-width: 960px) {
  .content-block-two-col{
    display: grid;
  }

  .content-block-about img{
    margin: 0;
    width: 100%;
  }
  
  .content-block-about .two-button-container{
    margin: 0 auto;
  }
  
}
@media screen and (max-width: 770px) {

  .hero-section-about{
    padding: 80px 0;
  }

  .hero-section-about h1{
    font-size: 36px;
  }

  .content-block-about h4{
    font-size: 18px;
  }

  .contact-block-container{
    display: grid;
    gap: 24px;
  }
}

@media screen and (max-width: 500px) {
  .hero-section-about{
    padding-bottom: 0;
  }

  .content-block-about{
    margin: 80px 0;
  }
}



















/*end*/
