/****** WHY SUBSCRIBE PAGE ******/

.hero-section-why{
    background-color: none;
    padding: 96px 0;
    text-align: center;
    height: auto;
}

.hero-section-why h1{
    font-weight: 600;
}

.hero-section-why span{
    color: #1CA800;
}

.content-block-why{
    margin-top: 64px;
    text-align: left;
}

.content-block-why h4{
    font-size: 20px;
    margin-bottom: 16px;
    font-family: 'Inter', sans-serif;
}

.content-block-why img{
    width: 848px;
}

.content-block-why strong{
    font-family: 'Inter', sans-serif;
}



/*------ MEDIA QUERIES ------*/
@media screen and (max-width: 960px) {
    .content-block-why img{
        width: 560px;
    }
}

@media screen and (max-width: 800px){
    .content-block-why img{
        width: 100%;
    }
}

@media screen and (max-width: 770px){
    .hero-section-why{
        padding: 80px 0;
    }

    .hero-section-why h1{
        font-size: 36px;
    }

    .content-block h4{
        font-size: 18px;
    }

    .content-block-why{
        margin-top: 48px;
    }
}






/* end */