/****** QUIZ PAGE CSS ******/

.hero-section-quiz{
  height: auto;
  padding: 96px 0 48px 0;
  text-align: center;
}

.hero-section-quiz h6{
  display: inline-block;
  border-radius: 8px;
  background-color: #1CA800;
  color: #FFFFFF;
  padding: 10px 16px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
}

.hero-section-quiz h1{
  width: 960px;
  font-size: 48px;
  font-weight: 600;
  margin: 16px auto 0 auto;
  color: #031101;
}

.quiz-meta-strip{
  background-color: #FCFFFB;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.quiz-meta-container{
  display: grid;
  border: 1px solid #DFE9DD;
  border-right: none;
  grid-template-columns: auto auto auto auto;
  justify-content: center;
}

.quiz-meta-block{
  padding: 20px;
  box-sizing: border-box;
  width: 320px;
  color: #031101;
  font-family: 'Inter', sans-serif;
  border-right: 1px solid #DFE9DD;
}

.quiz-meta-block > *, .quiz-meta-block p > *{
  font-family: 'Inter', sans-serif;
}

.quiz-meta-block h5{
  color: #B7C0B5;
  font-weight: 700;
}

.quiz-meta-block p{
  margin-top: 5px;
  font-weight: 500;
  color: #031101;
  font-size: 18px;
}

.quiz-prompt{
  width: 960px;
  background-color: #FCFFFB;
  border: 1px solid #DFE9DD;
  margin: 48px auto;
  padding: 32px;
  box-sizing: border-box;
}

.quiz-prompt > *{
  font-family: 'Inter', sans-serif;
}

.quiz-prompt h5{
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
  color: #031101;
}

.quiz-prompt h6{
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 500;
  color: #7A7E79;
}

.quiz-prompt p{
  line-height: 28px;
  letter-spacing: -0.2px;
  font-size: 18px;
  margin-bottom: 24px;
  color: #031101;
}

p.quiz-hint{
  color: #1CA800;
  margin-bottom: 0;
  font-size: 18px;
  padding: 12px;
  display: inline-block;
  background-color: rgba(28, 168, 0, 0.08);
  border-radius: 8px;
}

p.quiz-hint span{
  font-family: 'Inter', sans-serif;
  font-weight: 700;
}

.quiz-media{
  position: relative;
  margin: 0 auto;
  padding: 0;
  width: 960px;
  box-shadow: 0px 0px 16px -5px rgba(122,126,121,0.5);
  -webkit-box-shadow: 0px 0px 16px -5px rgba(122,126,121,0.5);
  -moz-box-shadow: 0px 0px 16px -5px rgba(122,126,121,0.5);
}

.quiz-media img{
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
}

.media-video-container{
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.media-video{
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.solution-toggle{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 960px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  background-color: #F3FBF2;
  border: 1px solid #1CA800;
  color: #1CA800;
  margin: 48px auto 0 auto;
  padding: 32px;
  box-sizing: border-box;
  transition: 0.3s;
}

.solution-toggle-open{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 960px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  background-color: #F3FBF2;
  border: 1px solid #1CA800;
  border-bottom: none;
  color: #1CA800;
  margin: 48px auto 0 auto;
  padding: 32px;
  box-sizing: border-box;
  transition: 0.3s;
}

.solution-toggle > *, .solution-toggle-open > *{
  font-family: 'Inter', sans-serif;
}

.solution-toggle:hover, .solution-toggle-open:hover{
  cursor: pointer;
}

.solution-box{
  width: 960px;
  border: 1px solid #1CA800;
  border-top: none;
  margin: 0 auto;
  padding: 32px;
  box-sizing: border-box;
  background-color: #F3FBF2;
  color: #031101;
}

.solution-intro{
  font-weight: 500;
  color: #7A7E79;
}

.solution-box ol{
  margin: 0;
  padding: 0 10px;
}

.solution-box li{
  margin-top: 48px;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  letter-spacing: -0.2px;
  font-weight: 700;
  line-height: 32px;
}

.solution-box img{
  margin-top: 16px;
  width: 480px;
  height: 270px;
}

.solution-box p{
  width: 720px;
  margin-top: 16px;
  font-family: 'Inter', sans-serif;
  letter-spacing: -0.2px;
  font-size: 18px;
  line-height: 28px;
}

.solution-box a{
  font-family: 'Inter', sans-serif;
  color: #1CA800;
}

.discuss-container{
  border: 1px solid #DFE9DD;
  width: 960px;
  margin: 48px auto 0 auto;
  padding: 32px;
  box-sizing: border-box;
  background-color: #FCFFFB;
}

.discuss-container > *{
  font-family: 'Inter', sans-serif;
}

.discuss-container h5{
  font-size: 20px;
  font-weight: 700;
}

.discuss-container h6{
  font-size: 18px;
  color: #7A7E79;
  margin-top: 8px;
  font-weight: 500;
}

.email-link{
  display: inline-block;
  margin-top: 32px;
  text-decoration: none;
}

.discuss-email{
  position: relative;
  display: flex;
  align-items: center;
  width: 404px;
  padding: 24px;
  box-sizing: border-box;
}

.discuss-email > div > *{
  font-family: 'Inter', sans-serif;
}

.discuss-email h6{
  font-size: 16px;
  margin-top: 0;
  font-weight: 700;
}

.discuss-email p{
  margin-top: 8px;
  font-size: 16px;
}

.discuss-email img{
  position: absolute;
  right: 24px;
  width: 24px;
  height: 24px;
}

.discuss-email{
  border: 1px solid #1CA800;
  background: rgba(28,168,0,0.08);
  color: #1CA800;
}

.discuss-email h6{
  color: #1CA800;
}

.like-it-container{
  border: 1px solid #DFE9DD;
  width: 960px;
  margin: 48px auto 80px auto;
  padding: 32px;
  box-sizing: border-box;
  background-color: #FCFFFB;
}

.like-it-container h5{
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 700;
}

.like-it-container h6{
  font-family: 'Inter', sans-serif;
  color: #7A7E79;
  font-size: 18px;
  margin-top: 8px;
  font-weight: 500;
}

.like-it-container .subscription-field{
  margin-top: 24px;
  border: 1px solid #DFE9DD;
}

.like-it-container .subscribe-button{
  margin-top: 24px;
}

.like-it-container .subscribe-button:hover{
  background-color: #031101;
  color: #FFFFFF;
}

.like-it-container p{
  margin-top: 24px;
  color: #031101;
}

.quiz-end{
  background-color: #031101;
  width: 960px;
  margin: 48px auto 80px auto;
  padding: 32px;
  box-sizing: border-box;
}

.quiz-end h4{
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #FFFFFF;
  letter-spacing: -0.2px;
  line-height: 32px;
}

.quiz-end p{
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  color: #B7C0B5;
  letter-spacing: -0.2px;
  line-height: 24px;
}

footer{
  border-top: 1px solid #DFE9DD;
}





/*------ MEDIA QUERIES ------*/
@media screen and (max-width: 1023px) {

  .hero-section-quiz h1{
    font-size: 36px;
    width: 800px;
  }

  .quiz-meta-block{
    width: 265px;
  }

  .quiz-meta-block h5{
    font-size: 12px;
  }

  .quiz-meta-block p{
    font-size: 14px;
  }

  .quiz-prompt{
    width: 800px;
    margin: 48px auto;
    font-size: 14px;
  }

  .quiz-media{
    width: auto;
    margin: 0 24px;
  }

  .solution-toggle, .solution-toggle-open{
    margin: 36px auto 0 auto;
    width: 800px;
  }

  .solution-box{
    width: 800px;
  }

  .solution-box p{
    width: auto;
  }

  .discuss-container{
    width: 800px;
  }

  .like-it-container{
    width: 800px;
  }

  .discuss-email{
    width: 360px;
  }

  .quiz-end{
    width: 800px;
  }
}

@media screen and (max-width: 848px) {
  .hero-section-quiz{
    padding: 80px 24px;
  }

  .hero-section-quiz h6{
    font-size: 12px;
  }

  .hero-section-quiz h1{
    font-size: 24px;
    width: auto;
  }

  .quiz-meta-container{
    grid-template-columns: 1fr;
    margin: 0 24px;
    width: 100%;
    border-bottom: none;
    justify-content: space-around;
  }

  .quiz-meta-block{
    width: auto;
    border-bottom: 1px solid #E8E6E6;
  }

  .quiz-prompt{
    margin: 48px 24px;
    padding: 20px;
    width: auto;
  }

  .quiz-prompt h5{
    font-size: 18px;
  }

  .quiz-prompt h6, .quiz-prompt p{
    font-size: 16px;
  }

  .solution-toggle, .solution-toggle-open{
    padding: 20px;
    margin: 36px 24px 0 24px;
    font-size: 18px;
    width: auto;
  }

  .solution-box{
    padding: 20px;
    margin: 0 24px;
    width: auto;
  }

  .solution-box li, .solution-box p{
    font-size: 16px;
  }

  .solution-box img{
    width: 100%;
    height: auto;
  }

  .discuss-container{
    padding: 20px;
    margin: 48px 24px 0 24px;
    width: auto;
  }

  .discuss-container h5{
    font-size: 18px;
  }

  .discuss-container h6{
    font-size: 16px;
  }

  .discuss-container p{
    font-size: 14px;
  }

  .email-link{
    display: block;
  }

  .discuss-email{
    padding: 16px;
    width: 100%;
  }

  .discuss-email h6{
    font-size: 14px;
  }

  .like-it-container{
    padding: 20px;
    margin: 48px 24px 64px 24px;
    width: auto;
  }

  .like-it-container h5{
    font-size: 18px;
  }

  .like-it-container h6{
    font-size: 16px;
  }

  .quiz-end{
    width: auto;
    margin: 48px 24px 80px 24px;
    padding: 20px;
  }

  .quiz-end h4, .quiz-end p{
    font-size: 16px;
  }

  .more-quizzes{
    grid-template-columns: auto;
  }
}

@media screen and (max-width: 560px){
  .like-it-container .subscription-field{
    width: 220px;
  }
}

@media screen and (max-width: 560px){
  .like-it-container .subscription-field{
    width: 200px;
  }
}





































/*end*/
